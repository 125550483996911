<template>
  <div class="orderMeal">
    <div class="banner"><img src="./img/banner.png" alt="" /></div>
    <div class="meal">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
        :commonOperaParams="commonOperaParams"
      >
        <div class="item" v-for="(item, index) in listData" :key="index">
          <div class="item-l"><img :src="item.imageUrls" alt="" /></div>
          <div class="item-r">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="des">
              {{ item.shortDesc }}
            </div>
            <div class="orderNum">已订{{ item.orderCount }}单</div>
            <div class="item-rb">
              <div class="price">¥{{ item.price }}</div>
              <div class="num">
                <div class="minus" v-if="item.num > 0" @click="minus(item)">
                  <img src="./img/minus.png" alt="" />
                </div>
                <div class="txt" v-if="item.num > 0">
                  {{ item.num }}
                </div>
                <div class="add" @click="add(item)">
                  <img src="./img/add.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <div class="btn" @click="toOrder">立即预定</div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { mealListUrl, addCartUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "orderMeal",
  data() {
    return {
      isDialog: false,
      listData: [],
      finishedText: "",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      postData: {
        num: 0,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
        activityCategory: 1,
      },
      bstop: 1,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // gloabalCount("", 20, 1);
  },
  methods: {
    async toOrder() {
      if (
        await toRegister(this.$route.path, this.$route.query, "热门活动报名")
      ) {
        let numArr = [];
        let idArr = [];
        this.listData.forEach((item) => {
          if (item.num && item.num != 0) {
            numArr.push(item.num);
            idArr.push(item.id);
          }
        });
        if (numArr.length == 0) {
          this.$toast({ message: "请选择套餐", duration: 2000 });
          return;
        }
        if (this.bstop != 1) {
          return;
        }
        this.bstop = 0;
        let res = await this.addCart();
        this.bstop = 1;
        if (res.code == 200) {
          this.$router.push({
            name: "toOrderMeal",
          });
        }
      }
    },
    add(item) {
      item.num++;
    },
    minus(item) {
      item.num--;
    },
    async addCart() {
      let numArr = [];
      let idArr = [];
      this.listData.forEach((item) => {
        if (item.num && item.num != 0) {
          numArr.push(item.num);
          idArr.push(item.id);
        }
      });

      let params = {
        mealIds: idArr.join(","),
        mealNums: numArr.join(","),
        userId: this.userId,
      };
      let res = await this.$axios.post(
        `${addCartUrl}`,
        this.$qs.stringify(params)
      );
      return res;
    },
    getMealList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios.get(`${mealListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            item.num = 0;
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    onLoad() {
      this.getMealList();
    },
    toClose() {
      this.isDialog = false;
    },
    toCamera() {
      this.$router.push({
        name: "rubbishSortList",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.orderMeal {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .banner {
    height: 339px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .meal {
    padding: 86px 30px 86px;
    .item {
      display: flex;
      height: 300px;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 30px;
      margin-bottom: 54px;
      .item-l {
        width: 300px;
        background: #bababa;
        border-radius: 30px;
        margin-right: 28px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 30px;
        }
      }
      .item-r {
        flex: 1;
        padding-top: 34px;
        min-width: 0;
        .title {
          font-size: 40px;
          font-weight: 400;
          color: #151c39;
          line-height: 56px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .des {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 32px;
          font-weight: 400;
          color: #151c39;
          line-height: 44px;
          margin: 6px 0;
        }
        .orderNum {
          font-size: 28px;
          font-weight: 400;
          color: #b4b4b4;
          line-height: 40px;
          margin-bottom: 42px;
        }
        .item-rb {
          display: flex;
          justify-content: space-between;
          align-content: center;
          padding-right: 34px;
          .price {
            font-size: 40px;
            font-weight: 400;
            color: #007eff;
            line-height: 56px;
          }
          .num {
            display: flex;
            align-content: center;
            .minus,
            .add {
              width: 50px;
              height: 50px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              margin: 0 18px;
              font-size: 40px;
              font-weight: 400;
              color: #151c39;
              line-height: 56px;
            }
          }
        }
      }
    }
  }
  .btn {
    width: 686px;
    height: 96px;
    background: #007eff;
    border-radius: 48px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    line-height: 96px;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
<style lang="less">
.orderMeal {
}
</style>
